<template>
  <div class="container-fluid footer d-flex align-items-center">
    <div class="container">
      <p>
        Avenida Centenario Esquina Benjamín Hill #481 Col. Adolfo López
        Mateos<br />C.P. 77010 Chetumal, Quintana Roo Teléfonos
        <a href="tel:123-456-7890">(983) 8322822</a> ext. 1123 y 1133.
      </p>
      <p>
        Correo electrónico para recepción de documentos:
        <a href="mailto:oic.ple@congresoqroo.gob.mx">
          oic.ple@congresoqroo.gob.mx
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.footer {
  text-align: center;
  background: $color-02;
  color: white;
  height: 200px;
  p {
    margin-bottom: 0;
    font-size: 0.75rem;
    @include media-breakpoint-up(md) {
      font-size: 0.9rem;
    }
    a {
      color: $color-03;
      &:hover {
      }
    }
  }
}
</style>
